import React from 'react';
import { Header } from '../../components/Organisms/Header/Header';
import { HomeVid } from '../../components/Organisms/HomeVid/HomeVid';

import { ContactForm } from '../../components/Organisms/ContactForm/ContactForm';
import { CotnactAbout } from '../../components/Organisms/CotnactAbout/CotnactAbout';
import { ContactPic } from '../../components/Molecules/ContactPic/ContactPic';
import { Footer } from '../../components/Organisms/Footer/Footer';
export function ContactPage() {
  return (
    <>
      <Header isFullWhite={true}>
        <h1>не має тут бути</h1>
      </Header>
      <ContactPic />
      <CotnactAbout />
      <ContactForm />
      <Footer skipR={true} />
    </>
  );
}
