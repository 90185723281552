import React, { useEffect, useState } from 'react';
import {
  TopContainer,
  TopContainerTab,
  TopContainerMob,
} from './StorageAbout.styles';
import { AboutVert } from '../../Atoms/AboutVert/AboutVert';
import { useTranslation } from 'react-i18next';
import { images } from '../../../static/exports';
import '../../../i18n';
import { CotnactAboutSec } from '../CotnactAboutSec/CotnactAboutSec'; // Corrected typo
import { AboutCompTempVert } from '../AboutCompTempVert/AboutCompTempVert';

export const StorageAbout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <>
      {windowWidth > 1660 ? (
        <>
          <TopContainer>
            <AboutCompTempVert
              picUrl={images.Foto11}
              compTxt={t('Whayc')}
              posN={0}
            />
            <AboutCompTempVert
              picUrl={images.Foto12}
              compTxt={t('WoAEw')}
              posN={45}
            />
            <AboutCompTempVert
              picUrl={images.Foto13}
              compTxt={t('WtEh')}
              posN={50}
            />
          </TopContainer>
          <CotnactAboutSec />
        </>
      ) : windowWidth > 1285 ? (
        <>
          <TopContainerTab>
            <AboutCompTempVert
              picUrl={images.Foto11}
              compTxt={t('Whayc')}
              posN={0}
            />
            <AboutCompTempVert
              picUrl={images.Foto12}
              compTxt={t('WoAEw')}
              posN={45}
            />
            <AboutCompTempVert
              picUrl={images.Foto13}
              compTxt={t('WtEh')}
              posN={50}
            />
          </TopContainerTab>
          <CotnactAboutSec />
        </>
      ) : (
        <>
          <TopContainerMob>
            <AboutCompTempVert
              picUrl={images.Foto11}
              compTxt={t('Whayc')}
              posN={0}
            />
            <AboutCompTempVert
              picUrl={images.Foto12}
              compTxt={t('WoAEw')}
              posN={45}
            />
            <AboutCompTempVert
              picUrl={images.Foto13}
              compTxt={t('WtEh')}
              posN={50}
            />
          </TopContainerMob>
          <CotnactAboutSec />
        </>
      )}
    </>
  );
};
