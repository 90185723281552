import React from 'react';
import { HeaderTitleText } from './HeaderTitle.styles';
import logo from '../../../static/logo.png'; // Changed import statement for logo

export function HeaderTitle() {
  return (
    <>
      <img className="logo" src={logo} alt="logo" />
      {/* <HeaderTitleText>BET Transport</HeaderTitleText> */}
    </>
  );
}
