import React, { useEffect, useState } from 'react';

import {
  Title,
  ImgBox,
  ContainerReverse,
  Text,
  Divider,
  DividerMob,
  ButtonLink,
  SocialLink,
  Container,
  ContainerReverseTab,
  ContainerReverseMob,
  ImgBoxMob,
  TitleMob,
} from './CotnactAbout.styles';
import { AboutVert } from '../../Atoms/AboutVert/AboutVert';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { images } from '../../../static/exports';

export const CotnactAbout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t, i18n } = useTranslation();
  return (
    <>
      {windowWidth > 1245 ? (
        <ContainerReverse>
          <Divider>
            <Title>{t('BetTrInc')}</Title>
            <Text>Email: bettransport@gmail.com</Text>
            <Text>{t('phone')}: + 1 514 347 1715 </Text>
            {/* <Text>
              {t('socmedia')}:<SocialLink to="/contact">Facebook</SocialLink>
              <SocialLink to="/contact">Instagram</SocialLink>
              <SocialLink to="/contact">Twitter</SocialLink>
            </Text> */}
          </Divider>
          <ImgBox src={images.Foto19} />
        </ContainerReverse>
      ) : windowWidth > 1030 ? (
        <ContainerReverseTab>
          <Divider>
            <Title>{t('BetTrInc')}</Title>
            <Text>Email: bettransport@gmail.com</Text>
            <Text>{t('phone')}: + 1 514 347 1715 </Text>
            {/* <Text>
              {t('socmedia')}:<SocialLink to="/contact">Facebook</SocialLink>
              <SocialLink to="/contact">Instagram</SocialLink>
              <SocialLink to="/contact">Twitter</SocialLink>
            </Text> */}
          </Divider>
          <ImgBox src={images.Foto19} />
        </ContainerReverseTab>
      ) : (
        <ContainerReverseMob>
          <DividerMob>
            <TitleMob>{t('BetTrInc')}</TitleMob>
            <Text>Email: bettransport@gmail.com</Text>
            <Text>{t('phone')}: + 1 514 347 1715 </Text>
            {/* <Text>
              {t('socmedia')}:<SocialLink to="/contact">Facebook</SocialLink>
              <SocialLink to="/contact">Instagram</SocialLink>
              <SocialLink to="/contact">Twitter</SocialLink>
            </Text> */}
          </DividerMob>
          <ImgBoxMob src={images.Foto19} />
        </ContainerReverseMob>
      )}
    </>
  );
};
