import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material/';
import trucksVid from '../../../static/trucksVid.mp4';

import cursor1 from '../../../static/close_icon.png';
import cursor2 from '../../../static/play-icon.png';

import { HomeVidStyledProps } from './HomeVid.behavior';

export const BoxStyled = styled(Box)<{ ismp?: boolean; imstp?: boolean }>(
  ({ theme, ismp, imstp }) => ({
    position: 'relative',
    cursor: imstp
      ? ismp
        ? `url(${cursor2}), auto`
        : `url(${cursor1}), auto`
      : 'auto',
  })
);

export const BoxStyledS = styled(Box)<{ ismp?: boolean; imstp?: boolean }>(
  ({ theme, ismp, imstp }) => ({
    position: 'relative',
    cursor: `url(${cursor2}), pointer`,
  })
);

export const HomeVidStyledMax = styled('video')<HomeVidStyledProps>(
  ({ theme }) => ({
    height: '100vh',
    width: '100%',
    objectFit: 'cover', // Add objectFit property
    backgroundPosition: 'right',
  })
);

export const HomeVidStyledMid = styled('video')<HomeVidStyledProps>(
  ({ theme }) => ({
    height: '100vh',
    width: '100%',
    objectFit: 'cover', // Add objectFit property
    backgroundPosition: 'right',
  })
);

export const HomeVidStyledMin = styled('video')<HomeVidStyledProps>(
  ({ theme }) => ({
    maxWidth: '100vw',
    width: '100%',
    height: '100vh',
    objectFit: 'cover', // Add objectFit property
    backgroundPosition: 'left',
  })
);
