import styled from 'styled-components';
import { Typography, TypographyProps, Link, LinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    // marginTop: '100px',
    fontSize: '45px',
  },
}));

export const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    marginTop: '20px',
    fontSize: '20px',
    // maxWidth: '50%',
  },
}));

export const TitleMob = styled(Typography)<TypographyProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    // marginTop: '100px',
    fontSize: '38px',
  },
}));

import { Box, BoxProps } from '@mui/material';

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '70%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '150px',
  marginBottom: '50px',
}));

export const ContainerReverse = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  width: '70%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const ContainerReverseTab = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  width: '85%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const ContainerReverseMob = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'center',
  width: '90%',
  maxWidth: '420px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
}));

export const DividerMob = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ImgBox = styled(Box)<BoxProps & { src?: string }>(
  ({ theme, src }) => ({
    height: '400px',
    width: '500px',
    backgroundPosition: 'center center',
    backgroundImage: `url("${src}")`,
    backgroundSize: 'cover',
  })
);

export const ImgBoxMob = styled(Box)<BoxProps & { src?: string }>(
  ({ theme, src }) => ({
    height: '350px',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundImage: `url("${src}")`,
    backgroundSize: 'cover',
  })
);

export const ButtonLink = styled(Link)<LinkProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    fontSize: '20px',
    backgroundColor: `rgba(0, 0, 0, 0.0)`,
    border: '#000 solid 3px',
    maxWidth: '200px',
    height: '40px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    borderRadius: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '800',
    margin: '20px 30px 30px 0',
    '&:hover': {
      color: '#fff',
      backgroundColor: `rgba(0, 0, 0, 1)`,
    },
  },
}));

export const SocialLink = styled(RouterLink)<LinkProps>(({ theme }) => ({
  textDecoration: 'none',
  marginBottom: '10px',
  marginLeft: '15px',
  color: '#575c59',
  '&:hover': {
    color: '#000',
  },
}));
