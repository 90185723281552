import styled from 'styled-components';

import { Box, BoxProps } from '@mui/material';

export const TopContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  width: '85%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '200px',
  // backgroundColor: 'pink',
}));

export const TopContainerTab = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '200px',
  // backgroundColor: 'pink',
}));

export const TopContainerMob = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '200px',
  // backgroundColor: 'pink',
}));
