import React, { useEffect, useState } from 'react';

import { WhyUsBox, WhyUsBoxTab, WhyUsBoxMob } from './WhyUs.styles';

import { WhyUsPic } from '../../Molecules/WhyUsPic/WhyUsPic';

export const WhyUs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth > 1525 ? (
        <WhyUsBox>
          <WhyUsPic />
        </WhyUsBox>
      ) : windowWidth > 1050 ? (
        <WhyUsBoxTab>
          <WhyUsPic />
        </WhyUsBoxTab>
      ) : (
        <WhyUsBoxMob>
          <WhyUsPic />
        </WhyUsBoxMob>
      )}
    </>
  );
};
