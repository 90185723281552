import React, { useEffect, useState } from 'react';

import { useForm, ValidationError } from '@formspree/react';

import {
  ContactFormBox,
  StyledInput,
  StyledTextArea,
  SubmitButton,
  StyledInputMob,
  StyledTextAreaMob,
} from './ContactForm.styles';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

export const ContactForm: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t, i18n } = useTranslation();

  const [state, handleSubmit] = useForm('mbjvvjdb');
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <>
      {windowWidth > 550 ? (
        <form action="https://formspree.io/f/mbjvvjdb" method="POST">
          <ContactFormBox>
            <h1>{t('contactus')} </h1>

            <Box
              sx={{
                display: 'flex',
                width: '520px',
                mt: '20px',
                justifyContent: 'space-between',
              }}
            >
              <StyledInput
                id="first_name"
                name="first_name"
                required
                label={t('FirstName')}
                variant="outlined"
              />
              <StyledInput
                id="last_name"
                name="last_name"
                label={t('LastName')}
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '520px',
                justifyContent: 'space-between',
              }}
            >
              <StyledInput
                id="email"
                name="email"
                required
                label="Email"
                variant="outlined"
              />
              <StyledInput
                id="number"
                name="number"
                required
                label={t('phone')}
                variant="outlined"
              />
            </Box>

            <StyledTextArea
              id="message"
              name="message"
              required
              label={t('Message')}
              variant="outlined"
              multiline={true}
            />
            <SubmitButton type="submit" variant="outlined">
              {t('Submit')}
            </SubmitButton>
          </ContactFormBox>
        </form>
      ) : (
        <form action="https://formspree.io/f/mbjvvjdb" method="POST">
          <ContactFormBox>
            <h1>{t('contactus')} </h1>

            <StyledInputMob
              id="first_name"
              name="first_name"
              required
              label={t('FirstName')}
              variant="outlined"
            />
            <StyledInputMob
              id="last_name"
              name="last_name"
              label={t('LastName')}
              variant="outlined"
            />

            <StyledInputMob
              id="email"
              name="email"
              required
              label="Email"
              variant="outlined"
            />
            <StyledInputMob
              id="number"
              name="number"
              required
              label={t('phone')}
              variant="outlined"
            />

            <StyledTextAreaMob
              id="message"
              name="message"
              required
              label={t('Message')}
              variant="outlined"
              multiline={true}
            />
            <SubmitButton type="submit" variant="outlined">
              {t('Submit')}
            </SubmitButton>
          </ContactFormBox>
        </form>
      )}
    </>
  );
};
