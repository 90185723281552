import React, { useEffect, useState } from 'react';

import {
  FooterBox,
  Container,
  ContainerCop,
  Title,
  CustomLink,
  ListBox,
  ListLink,
  Divider,
  LinkBoxTitle,
  FooterBoxSh,
  TitleMid,
  ContainerMob,
  CustomLinkMid,
  ListBoxMid,
  DividerCol,
  ListBoxMob,
  CustomLinkMob,
  FooterBoxMob,
  ListLinkMob,
  LinkBoxTitleMob,
  ContainerCopMob,
  ContainerSh,
  ContainerMobSh,
} from './Footer.styles';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

export const Footer: React.FC<{ skipR?: boolean }> = ({ skipR }) => {
  const { t, i18n } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!skipR ? (
        windowWidth > 900 ? (
          <FooterBox>
            <Container>
              <Divider>
                <Title>{t('rtwwu')}</Title>
                <CustomLink to="/contact">{t('contactus')}</CustomLink>
              </Divider>
              <Divider>
                <ListBox>
                  <LinkBoxTitle>{t('service')}</LinkBoxTitle>
                  <ListLink to="/storage">{t('storage')}</ListLink>
                  <ListLink to="/sameDayDelivery">{t('SDDS')}</ListLink>
                  <ListLink to="/LTL">{t('LTL')}</ListLink>
                </ListBox>
                <ListBox>
                  <LinkBoxTitle>{t('contactinfo')}</LinkBoxTitle>
                  <ListLink to="/#">transportbet@gmail.com</ListLink>
                </ListBox>
                <ListBox>
                  <LinkBoxTitle>{t('socmedia')}</LinkBoxTitle>
                  <ListLink to="/contact">Instagram</ListLink>
                  <ListLink to="/contact">Facebook</ListLink>
                </ListBox>
                <ListBox></ListBox>
              </Divider>
            </Container>
            <ContainerCop>{t('copyright')}</ContainerCop>{' '}
            <ContainerCop>{t('wmb')}</ContainerCop>
          </FooterBox>
        ) : windowWidth > 600 ? (
          <FooterBox>
            <ContainerMob>
              <Divider>
                <TitleMid>{t('rtwwu')}</TitleMid>
                <CustomLinkMid to="/contact">{t('contactus')}</CustomLinkMid>
              </Divider>
              <Divider>
                <ListBoxMid>
                  <LinkBoxTitle>{t('service')}</LinkBoxTitle>
                  <ListLink to="/storage">{t('storage')}</ListLink>
                  <ListLink to="/sameDayDelivery">{t('SDDS')}</ListLink>
                  <ListLink to="/LTL">{t('LTL')}</ListLink>
                </ListBoxMid>
                <ListBoxMid>
                  <LinkBoxTitle>{t('contactinfo')}</LinkBoxTitle>
                  <ListLink to="/#">transportbet@gmail.com</ListLink>
                </ListBoxMid>
                <ListBoxMid>
                  <LinkBoxTitle>{t('socmedia')}</LinkBoxTitle>
                  <ListLink to="/contact">Instagram</ListLink>
                  <ListLink to="/contact">Facebook</ListLink>
                </ListBoxMid>
                <ListBoxMid></ListBoxMid>
              </Divider>
            </ContainerMob>
            <ContainerCop>{t('copyright')}</ContainerCop>{' '}
            <ContainerCop>{t('wmb')}</ContainerCop>
          </FooterBox>
        ) : (
          <FooterBoxMob>
            <ContainerMob>
              <DividerCol>
                <TitleMid>{t('rtwwu')}</TitleMid>
                <CustomLinkMob to="/contact">{t('contactus')}</CustomLinkMob>
              </DividerCol>
              <DividerCol>
                <ListBoxMob>
                  <LinkBoxTitleMob>{t('service')}</LinkBoxTitleMob>
                  <ListLinkMob to="/storage">{t('storage')}</ListLinkMob>
                  <ListLinkMob to="/sameDayDelivery">{t('SDDS')}</ListLinkMob>
                  <ListLinkMob to="/LTL">{t('LTL')}</ListLinkMob>
                </ListBoxMob>
                <ListBoxMob>
                  <LinkBoxTitleMob>{t('contactinfo')}</LinkBoxTitleMob>
                  <ListLinkMob to="/#">transportbet@gmail.com</ListLinkMob>
                </ListBoxMob>
                <LinkBoxTitleMob>
                  <LinkBoxTitleMob>{t('socmedia')}</LinkBoxTitleMob>
                  <ListLinkMob to="/contact">Instagram</ListLinkMob>
                  <ListLinkMob to="/contact">Facebook</ListLinkMob>
                </LinkBoxTitleMob>
                <ListBoxMob></ListBoxMob>
              </DividerCol>
            </ContainerMob>
            <ContainerCopMob>{t('copyright')}</ContainerCopMob>{' '}
            <ContainerCop>{t('wmb')}</ContainerCop>
          </FooterBoxMob>
        )
      ) : windowWidth > 900 ? (
        <FooterBoxSh>
          <ContainerSh>
            <Divider />
            <Divider>
              <ListBox>
                <LinkBoxTitle>{t('service')}</LinkBoxTitle>
                <ListLink to="/storage">{t('storage')}</ListLink>
                <ListLink to="/sameDayDelivery">{t('SDDS')}</ListLink>
                <ListLink to="/LTL">{t('LTL')}</ListLink>
              </ListBox>
              <ListBox>
                <LinkBoxTitle>{t('contactinfo')}</LinkBoxTitle>
                <ListLink to="/#">transportbet@gmail.com</ListLink>
              </ListBox>
              <ListBox>
                <LinkBoxTitle>{t('socmedia')}</LinkBoxTitle>
                <ListLink to="/contact">Instagram</ListLink>
                <ListLink to="/contact">Facebook</ListLink>
              </ListBox>
              <ListBox></ListBox>
            </Divider>
          </ContainerSh>
          <ContainerCop>{t('copyright')}</ContainerCop>{' '}
          <ContainerCop>{t('wmb')}</ContainerCop>
        </FooterBoxSh>
      ) : windowWidth > 600 ? (
        <FooterBoxSh>
          <ContainerMobSh>
            <Divider />
            <Divider>
              <ListBoxMid>
                <LinkBoxTitle>{t('service')}</LinkBoxTitle>
                <ListLink to="/storage">{t('storage')}</ListLink>
                <ListLink to="/sameDayDelivery">{t('SDDS')}</ListLink>
                <ListLink to="/LTL">{t('LTL')}</ListLink>
              </ListBoxMid>
              <ListBoxMid>
                <LinkBoxTitle>{t('contactinfo')}</LinkBoxTitle>
                <ListLink to="/#">transportbet@gmail.com</ListLink>
              </ListBoxMid>
              <ListBoxMid>
                <LinkBoxTitle>{t('socmedia')}</LinkBoxTitle>
                <ListLink to="/contact">Instagram</ListLink>
                <ListLink to="/contact">Facebook</ListLink>
              </ListBoxMid>
              <ListBoxMid></ListBoxMid>
            </Divider>
          </ContainerMobSh>
          <ContainerCop>{t('copyright')}</ContainerCop>
          <ContainerCop>{t('wmb')}</ContainerCop>
        </FooterBoxSh>
      ) : (
        <FooterBoxMob>
          <ContainerMobSh>
            <Divider />
            <DividerCol>
              <ListBoxMob>
                <LinkBoxTitleMob>{t('service')}</LinkBoxTitleMob>
                <ListLinkMob to="/storage">{t('storage')}</ListLinkMob>
                <ListLinkMob to="/sameDayDelivery">{t('SDDS')}</ListLinkMob>
                <ListLinkMob to="/LTL">{t('LTL')}</ListLinkMob>
              </ListBoxMob>
              <ListBoxMob>
                <LinkBoxTitleMob>{t('contactinfo')}</LinkBoxTitleMob>
                <ListLinkMob to="/#">transportbet@gmail.com</ListLinkMob>
              </ListBoxMob>
              <LinkBoxTitleMob>
                <LinkBoxTitleMob>{t('socmedia')}</LinkBoxTitleMob>
                <ListLinkMob to="/contact">Instagram</ListLinkMob>
                <ListLinkMob to="/contact">Facebook</ListLinkMob>
              </LinkBoxTitleMob>
              <ListBoxMob></ListBoxMob>
            </DividerCol>
          </ContainerMobSh>
          <ContainerCopMob>{t('copyright')}</ContainerCopMob>
          <ContainerCop>{t('wmb')}</ContainerCop>
        </FooterBoxMob>
      )}
    </>
  );
};
