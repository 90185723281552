import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

import {
  HeaderBox,
  HeaderBoxClear,
  StyledSearch, // Assuming these are your styled components
  SearchIconWrapper,
  StyledInputBase,
  MobMenBox,
  MobMenBoxIcon,
  HeaderBoxMin,
  HeaderBoxMid,
  HeaderBoxMob,
  IconButtonStyled,
  HeaderBoxMinClear,
  HeaderBoxMidClear,
  HeaderBoxMobClear,
} from './Header.styles';

import { HeaderTitle } from '../../Molecules/HeaderTitle/HeaderTitle';
import {
  HeaderLinks,
  HeaderLinksMob,
} from '../../Molecules/HeaderLinks/HeaderLinks';
import { HeaderButton } from '../../Atoms/HeaderButton/HeaderButton';

interface Props {
  window?: () => Window;
  children: React.ReactElement<any, any>;
  isFullWhite?: boolean;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export const Header = (props: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { isFullWhite } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setState] = useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 320 ||
        document.documentElement.scrollTop > 320
      ) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', scrollFunction);

    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  return (
    <>
      {windowWidth > 1600 ? (
        isScrolled === false && !isFullWhite ? (
          <HideOnScroll {...props}>
            <HeaderBoxClear>
              <HeaderTitle />
              <HeaderLinks />
              {/* <HeaderButton /> */}
            </HeaderBoxClear>
          </HideOnScroll>
        ) : (
          <HideOnScroll {...props}>
            <HeaderBox>
              <HeaderTitle />
              <HeaderLinks />
              {/* <HeaderButton /> */}
            </HeaderBox>
          </HideOnScroll>
        )
      ) : windowWidth > 1330 ? (
        isScrolled === false && !isFullWhite ? (
          <HideOnScroll {...props}>
            <HeaderBoxMidClear>
              <HeaderTitle />
              <HeaderLinks />
              {/* <HeaderButton /> */}
            </HeaderBoxMidClear>
          </HideOnScroll>
        ) : (
          <HideOnScroll {...props}>
            <HeaderBoxMid>
              <HeaderTitle />
              <HeaderLinks />
              {/* <HeaderButton /> */}
            </HeaderBoxMid>
          </HideOnScroll>
        )
      ) : windowWidth > 1100 ? (
        isScrolled === false && !isFullWhite ? (
          <HideOnScroll {...props}>
            <HeaderBoxMinClear>
              <HeaderTitle />
              <HeaderLinks />
              {/* <HeaderButton /> */}
            </HeaderBoxMinClear>
          </HideOnScroll>
        ) : (
          <HideOnScroll {...props}>
            <HeaderBoxMin>
              <HeaderTitle />
              <HeaderLinks />
              {/* <HeaderButton /> */}
            </HeaderBoxMin>
          </HideOnScroll>
        )
      ) : isScrolled === false && !isFullWhite ? (
        <>
          <CssBaseline />
          <HideOnScroll {...props}>
            <HeaderBoxMobClear>
              <HeaderTitle />

              <IconButton
                edge="start"
                color="default"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                sx={{
                  ml: 'auto',
                  mr: 2,
                }}
              >
                <MenuIcon />
              </IconButton>

              <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
                <MobMenBox>
                  <MobMenBoxIcon>
                    <IconButtonStyled sx={{ ml: 'auto', mb: 2 }}>
                      <CloseIcon onClick={toggleDrawer(false)} />
                    </IconButtonStyled>
                  </MobMenBoxIcon>

                  <Divider sx={{ mb: 2 }} />

                  <Box sx={{ mb: 2 }}>
                    <HeaderLinksMob />
                  </Box>
                </MobMenBox>
              </Drawer>
            </HeaderBoxMobClear>
          </HideOnScroll>
        </>
      ) : (
        <>
          <CssBaseline />
          <HideOnScroll {...props}>
            <HeaderBoxMob>
              <HeaderTitle />

              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                sx={{
                  ml: 'auto',
                  mr: 2,
                }}
              >
                <MenuIcon />
              </IconButton>

              <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
                <MobMenBox>
                  <MobMenBoxIcon>
                    <IconButtonStyled sx={{ ml: 'auto', mb: 2 }}>
                      <CloseIcon onClick={toggleDrawer(false)} />
                    </IconButtonStyled>
                  </MobMenBoxIcon>

                  <Divider sx={{ mb: 2 }} />

                  <Box sx={{ mb: 2 }}>
                    <HeaderLinksMob />
                  </Box>
                </MobMenBox>
              </Drawer>
            </HeaderBoxMob>
          </HideOnScroll>
        </>
      )}
    </>
  );
};
