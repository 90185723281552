import React, { useEffect, useState } from 'react';

import { CotnactAboutSec } from '../CotnactAboutSec/CotnactAboutSec';
import { AboutVert } from '../../Atoms/AboutVert/AboutVert';
import { images } from '../../../static/exports';

import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { AboutCompTemp } from '../AboutCompTemp/AboutCompTemp';
export const MovingAbout = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <AboutCompTemp
        isTitled={true}
        isReverse={false}
        picUrl={images.Foto21}
        titleTxt={t('SDDTitle')}
        compTxt={t('movingT1')}
      />
      <AboutCompTemp
        isReverse={true}
        picUrl={images.Foto22}
        compTxt={t('movingT2')}
      />
      <AboutCompTemp
        isTitled={true}
        isReverse={false}
        titleTxt={t('SDDTitl2')}
        picUrl={images.Foto23}
        compTxt={t('movingT3')}
      />
      <CotnactAboutSec isReverse={true} />
    </>
  );
};
