import React, { useEffect, useState } from 'react';

import {
  Text,
  ImgBox,
  Divider,
  DividerReverse,
  DividerMob,
  Container,
  ContainerReverse,
  ContainerReverseTab,
  TextTab,
  TextMob,
  ContainerTab,
  ContainerMob,
  Title,
  TitleMob,
  ButtonLink,
  ButtonLinkTab,
  ButtonLinkMob,
} from './AboutCompTemp.styles';

import { AboutVert } from '../../Atoms/AboutVert/AboutVert';

interface CotnactAboutSecProps {
  isReverse?: boolean;
  picUrl: string;
  compTxt: string;
  isTitled?: boolean;
  titleTxt?: string;
  isButton?: boolean;
  posN?: number;
}

import { useTranslation } from 'react-i18next';
import '../../../i18n';

export const AboutCompTemp: React.FC<CotnactAboutSecProps> = ({
  isReverse = false,
  picUrl,
  compTxt,
  isTitled = false,
  titleTxt,
  isButton = false,
  posN = 0,
}) => {
  const ContainerComponent = isReverse ? ContainerReverse : Container;
  const ContainerComponentTab = isReverse ? ContainerReverseTab : ContainerTab;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <>
      {windowWidth > 1360 ? (
        <ContainerComponent>
          <Divider>
            {isTitled ? <Title>{titleTxt}</Title> : null}
            <Text>{compTxt}</Text>
            {isButton ? (
              <ButtonLink href="/contact">{t('contactus')}</ButtonLink>
            ) : (
              ''
            )}
          </Divider>
          <ImgBox pos={posN} src={picUrl} />
        </ContainerComponent>
      ) : windowWidth > 1200 ? (
        <ContainerComponent>
          <Divider>
            {isTitled ? <Title>{titleTxt}</Title> : null}
            <TextTab>{compTxt}</TextTab>
            {isButton ? (
              <ButtonLink href="/contact">{t('contactus')}</ButtonLink>
            ) : (
              ''
            )}
          </Divider>
          <ImgBox pos={posN} src={picUrl} />
        </ContainerComponent>
      ) : windowWidth > 1100 ? (
        <ContainerComponentTab>
          <Divider>
            {isTitled ? <Title>{titleTxt}</Title> : null}
            <Text>{compTxt}</Text>
            {isButton ? (
              <ButtonLink href="/contact">{t('contactus')}</ButtonLink>
            ) : (
              ''
            )}
          </Divider>
          <ImgBox pos={posN} src={picUrl} />
        </ContainerComponentTab>
      ) : windowWidth > 1000 ? (
        <ContainerComponentTab>
          <Divider>
            {isTitled ? <Title>{titleTxt}</Title> : null}
            <Text>{compTxt}</Text>
            {isButton ? (
              <ButtonLinkTab href="/contact">{t('contactus')}</ButtonLinkTab>
            ) : (
              ''
            )}
          </Divider>
          <ImgBox pos={posN} src={picUrl} />
        </ContainerComponentTab>
      ) : (
        <ContainerMob>
          <DividerReverse>
            <DividerMob>
              {isTitled ? <TitleMob>{titleTxt}</TitleMob> : null}
              <TextMob>{compTxt}</TextMob>
              {isButton ? (
                <ButtonLinkMob href="/contact">{t('contactus')}</ButtonLinkMob>
              ) : (
                ''
              )}
            </DividerMob>
            <ImgBox pos={posN} src={picUrl} />
          </DividerReverse>
        </ContainerMob>
      )}
    </>
  );
};
