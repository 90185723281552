import React, { useEffect, useState } from 'react';

import {
  Text,
  ImgBox,
  Container,
  TextTab,
  ContainerTab,
  ContainerMob,
  ContainerTabM,
  ImgBoxTab,
  ContainerTabMI,
  ImgBoxTabM,
  ImgBoxMobile,
  TextMob,
  ButtonLinkMob,
  ButtonLinkTab,
  ButtonLink,
  TxtContainer,
} from './AboutCompTempVert.styles';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface CotnactAboutSecProps {
  picUrl: string;
  compTxt: string;
  posN: number;
  isButton?: boolean;
  buttonLink?: string;
}

export const AboutCompTempVert: React.FC<CotnactAboutSecProps> = ({
  picUrl,
  compTxt,
  posN,
  isButton = false,
  buttonLink,
}) => {
  const { t, i18n } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth > 1660 ? (
        <Container>
          <ImgBox pos={posN} src={picUrl} />
          <TxtContainer>
            <Text>{compTxt}</Text>
          </TxtContainer>

          {isButton ? (
            <ButtonLink href={buttonLink}>{t('Readmore')}</ButtonLink>
          ) : (
            <></>
          )}
        </Container>
      ) : windowWidth > 1575 ? (
        <ContainerTab>
          <ImgBox pos={posN} src={picUrl} />
          <TxtContainer>
            <TextTab>{compTxt}</TextTab>
          </TxtContainer>
          {isButton ? (
            <ButtonLink href={buttonLink}>{t('Readmore')}</ButtonLink>
          ) : (
            <></>
          )}
        </ContainerTab>
      ) : windowWidth > 1385 ? (
        <ContainerTabM>
          <ImgBoxTab pos={posN} src={picUrl} />
          <TxtContainer>
            <TextTab>{compTxt}</TextTab>
          </TxtContainer>
          {isButton ? (
            <ButtonLink href={buttonLink}>{t('Readmore')}</ButtonLink>
          ) : (
            <></>
          )}
        </ContainerTabM>
      ) : windowWidth > 1285 ? (
        <ContainerTabMI>
          <ImgBoxTabM pos={posN} src={picUrl} />
          <TxtContainer>
            <TextTab>{compTxt}</TextTab>
          </TxtContainer>
          {isButton ? (
            <ButtonLinkTab href={buttonLink}>{t('Readmore')}</ButtonLinkTab>
          ) : (
            <></>
          )}
        </ContainerTabMI>
      ) : (
        <ContainerMob>
          <ImgBoxMobile pos={posN} src={picUrl} />
          <TextMob>{compTxt}</TextMob>
          {isButton ? (
            <ButtonLinkMob href={buttonLink}>{t('Readmore')}</ButtonLinkMob>
          ) : (
            <></>
          )}
        </ContainerMob>
      )}
    </>
  );
};
