import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import {
  ModernPicBox,
  ModernPicBoxIns,
  ModernPicBoxInsTab,
  ModernPicBoxInsMob,
} from './ModernPic.style';

export const ModernPic: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <ModernPicBox>
        {windowWidth > 900 ? (
          <ModernPicBoxIns>{t('beBET')}</ModernPicBoxIns>
        ) : windowWidth > 570 ? (
          <ModernPicBoxInsTab>{t('beBET')}</ModernPicBoxInsTab>
        ) : (
          <ModernPicBoxInsMob>{t('beBET')}</ModernPicBoxInsMob>
        )}
      </ModernPicBox>
    </>
  );
};
