import React from 'react';

import { AboutVert } from '../../Atoms/AboutVert/AboutVert';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { images } from '../../../static/exports';
import { CotnactAboutSec } from '../CotnactAboutSec/CotnactAboutSec';
import { AboutCompTemp } from '../AboutCompTemp/AboutCompTemp';
export const LTLAbout = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <AboutCompTemp
        isReverse={true}
        isTitled={true}
        titleTxt={t('LTLTitle')}
        picUrl={images.Foto15}
        compTxt={t('LTLAbout')}
      />
      <AboutCompTemp
        isReverse={false}
        isTitled={true}
        titleTxt={t('FTLTitle')}
        picUrl={images.Foto16}
        compTxt={t('FTL')}
      />
      <AboutCompTemp
        isReverse={true}
        isTitled={true}
        titleTxt={t('SDTTitle')}
        picUrl={images.Foto17}
        compTxt={t('SDTAbout')}
      />
      <AboutCompTemp
        isReverse={false}
        isTitled={true}
        titleTxt={t('TCTTitle')}
        picUrl={images.Foto18}
        compTxt={t('TCT')}
      />
      <CotnactAboutSec isReverse={true} />
    </>
  );
};
