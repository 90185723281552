import React, { useEffect, useRef, useState } from 'react';

import { HomeTitleStyled, HomeTitleStyledMob } from './HomeTitle.styles';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

export const HomeTitle = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {windowWidth > 500 ? (
        <HomeTitleStyled>
          BET Transport <br /> {t('fastDescription')}
        </HomeTitleStyled>
      ) : (
        <HomeTitleStyledMob>
          BET Transport <br /> {t('fastDescription')}
        </HomeTitleStyledMob>
      )}
    </>
  );
};
