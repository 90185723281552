import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Title,
  Text,
  ImgBox,
  Divider,
  DividerReverse,
  DividerMob,
  Container,
  ContainerReverse,
  ContainerReverseTab,
  ButtonLink,
  ButtonLinkTab,
  ButtonLinkMob,
  TextTab,
  TextMob,
  ContainerTab,
  ContainerMob,
} from './CotnactAboutSec.styles';

import { AboutVert } from '../../Atoms/AboutVert/AboutVert';
import { images } from '../../../static/exports';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface CotnactAboutSecProps {
  isReverse?: boolean;
}

export const CotnactAboutSec: React.FC<CotnactAboutSecProps> = ({
  isReverse = false,
}) => {
  const { t, i18n } = useTranslation();

  const ContainerComponent = isReverse ? ContainerReverse : Container;
  const ContainerComponentTab = isReverse ? ContainerReverseTab : ContainerTab;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {windowWidth > 1360 ? (
        <ContainerComponent>
          <Divider>
            <Text>{t('movingT4')}</Text>
            <ButtonLink href="/contact">{t('getAquote')}</ButtonLink>
          </Divider>
          <ImgBox src={images.Foto10} />
        </ContainerComponent>
      ) : windowWidth > 1200 ? (
        <ContainerComponent>
          <Divider>
            <TextTab>{t('movingT4')}</TextTab>
            <ButtonLink href="/contact">{t('getAquote')}</ButtonLink>
          </Divider>
          <ImgBox src={images.Foto10} />
        </ContainerComponent>
      ) : windowWidth > 1100 ? (
        <ContainerComponentTab>
          <Divider>
            <Text>{t('movingT4')}</Text>
            <ButtonLink href="/contact">{t('getAquote')}</ButtonLink>
          </Divider>
          <ImgBox src={images.Foto10} />
        </ContainerComponentTab>
      ) : windowWidth > 1000 ? (
        <ContainerComponentTab>
          <Divider>
            <Text>{t('movingT4')}</Text>
            <ButtonLinkTab href="/contact">{t('getAquote')}</ButtonLinkTab>
          </Divider>
          <ImgBox src={images.Foto10} />
        </ContainerComponentTab>
      ) : (
        <ContainerMob>
          <DividerReverse>
            <DividerMob>
              <TextMob>{t('movingT4')}</TextMob>
              <ButtonLinkMob href="/contact">{t('getAquote')}</ButtonLinkMob>
            </DividerMob>
            <ImgBox src={images.Foto10} />
          </DividerReverse>
        </ContainerMob>
      )}
    </>
  );
};

CotnactAboutSec.propTypes = {
  isReverse: PropTypes.bool,
};
