import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {
  Navigation,
  Pagination,
  A11y,
  EffectFade,
  Autoplay,
} from 'swiper/modules';
import SwiperCore from 'swiper';
import {
  Container,
  ModernPicBox,
  ModernPicBoxIns,
  ModernPicBoxInsTxt,
  ButtonLink,
  ButtonLinkMob,
  ModernPicBoxInsMob,
  ContainerTab,
  ContainerMob,
  ModernPicBoxInsTxtMob,
} from './HomeSlide.styles';
import { images } from '../../../static/exports';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
// Install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y, EffectFade, Autoplay]);

export const SimpleSlider: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLargeScreen = windowWidth > 1700;
  const { t, i18n } = useTranslation();
  const ColDivider = windowWidth > 885 ? ModernPicBoxIns : ModernPicBoxInsMob;
  const ButtonLinkIsMob = windowWidth > 885 ? ButtonLink : ButtonLinkMob;
  return (
    <>
      {windowWidth > 1250 ? (
        <Container>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{ clickable: true }}
          >
            <SwiperSlide className="res-slide">
              <ModernPicBox src={images.Foto1}>
                <ModernPicBoxIns>
                  <ModernPicBoxInsTxt>{t('saveOnTime')}</ModernPicBoxInsTxt>
                  <ButtonLink href="/contact">{t('UCBOU')}</ButtonLink>
                </ModernPicBoxIns>
              </ModernPicBox>
            </SwiperSlide>
            <SwiperSlide className="res-slide">
              <ModernPicBox src={images.Foto2}>
                <ModernPicBoxIns>
                  <ModernPicBoxInsTxt>{t('profTransport')}</ModernPicBoxInsTxt>
                  <ButtonLink href="/contact">{t('UCBOU')}</ButtonLink>
                </ModernPicBoxIns>
              </ModernPicBox>
            </SwiperSlide>

            {/* Navigation Arrows */}
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </Swiper>
        </Container>
      ) : windowWidth > 1100 ? (
        <>
          <ContainerTab>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              autoplay={{ delay: 3000, disableOnInteraction: true }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              pagination={{ clickable: true }}
            >
              <SwiperSlide className="res-slide">
                <ModernPicBox src={images.Foto1}>
                  <ModernPicBoxIns>
                    <ModernPicBoxInsTxt>{t('saveOnTime')}</ModernPicBoxInsTxt>
                    <ButtonLink href="/contact">{t('UCBOU')}</ButtonLink>
                  </ModernPicBoxIns>
                </ModernPicBox>
              </SwiperSlide>
              <SwiperSlide className="res-slide">
                <ModernPicBox src={images.Foto2}>
                  <ModernPicBoxIns>
                    <ModernPicBoxInsTxt>
                      {t('profTransport')}
                    </ModernPicBoxInsTxt>
                    <ButtonLink href="/contact">{t('UCBOU')}</ButtonLink>
                  </ModernPicBoxIns>
                </ModernPicBox>
              </SwiperSlide>

              {/* Navigation Arrows */}
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </Swiper>
          </ContainerTab>
        </>
      ) : (
        <>
          <ContainerMob>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              autoplay={{ delay: 3000, disableOnInteraction: true }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              pagination={{ clickable: true }}
            >
              <SwiperSlide className="res-slide">
                <ModernPicBox src={images.Foto1}>
                  <ColDivider>
                    {windowWidth > 945 ? (
                      <ModernPicBoxInsTxt>{t('saveOnTime')}</ModernPicBoxInsTxt>
                    ) : (
                      <ModernPicBoxInsTxtMob>
                        {t('saveOnTime')}
                      </ModernPicBoxInsTxtMob>
                    )}
                    <ButtonLinkIsMob href="/contact">
                      {t('UCBOU')}
                    </ButtonLinkIsMob>
                  </ColDivider>
                </ModernPicBox>
              </SwiperSlide>
              <SwiperSlide className="res-slide">
                <ModernPicBox src={images.Foto2}>
                  <ColDivider>
                    {windowWidth > 945 ? (
                      <ModernPicBoxInsTxt>
                        {t('profTransport')}
                      </ModernPicBoxInsTxt>
                    ) : (
                      <ModernPicBoxInsTxtMob>
                        {t('profTransport')}
                      </ModernPicBoxInsTxtMob>
                    )}
                    <ButtonLinkIsMob href="/contact">
                      {t('UCBOU')}
                    </ButtonLinkIsMob>
                  </ColDivider>
                </ModernPicBox>
              </SwiperSlide>

              {/* Navigation Arrows */}
            </Swiper>
          </ContainerMob>
        </>
      )}
    </>
  );
};
