import React, { useEffect, useState } from 'react';

import {
  Text,
  ImgBox,
  Container,
  TextTab,
  ContainerTab,
  ContainerMob,
  ContainerTabM,
  ImgBoxTab,
  ContainerTabMI,
  ImgBoxTabM,
  ImgBoxMobile,
  TextMob,
  ButtonLinkMob,
  ButtonLinkTab,
  ButtonLink,
} from './AboutCompTempVertList.styles';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface AboutCompTempVertListProps {
  picUrl: string;
  posN: number;
  listItem1: string;
  listItem2: string;
  listItem3: string;
  listItem4: string;
  isButton?: boolean;
  buttonLink?: string;
}

export const AboutCompTempVertList: React.FC<AboutCompTempVertListProps> = ({
  picUrl,
  posN,
  listItem1,
  listItem2,
  listItem3,
  listItem4,
  isButton = false,
  buttonLink,
}) => {
  const { t, i18n } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {windowWidth > 1660 ? (
        <Container>
          <ImgBox pos={posN} src={picUrl} />
          <Text>• {listItem1}</Text>
          <Text>• {listItem2}</Text>
          <Text>• {listItem3}</Text>
          <Text>• {listItem4}</Text>
          {isButton ? (
            <ButtonLink href={buttonLink}>{t('Readmore')}</ButtonLink>
          ) : (
            <></>
          )}
        </Container>
      ) : windowWidth > 1575 ? (
        <ContainerTab>
          <ImgBox pos={posN} src={picUrl} />
          <TextTab>• {listItem1}</TextTab>
          <TextTab>• {listItem2}</TextTab>
          <TextTab>• {listItem3}</TextTab>
          <TextTab>• {listItem4}</TextTab>
          {isButton ? (
            <ButtonLink href={buttonLink}>{t('Readmore')}</ButtonLink>
          ) : (
            <></>
          )}
        </ContainerTab>
      ) : windowWidth > 1385 ? (
        <ContainerTabM>
          <ImgBoxTab pos={posN} src={picUrl} />
          <TextTab>• {listItem1}</TextTab>
          <TextTab>• {listItem2}</TextTab>
          <TextTab>• {listItem3}</TextTab>
          <TextTab>• {listItem4}</TextTab>
          {isButton ? (
            <ButtonLink href={buttonLink}>{t('Readmore')}</ButtonLink>
          ) : (
            <></>
          )}
        </ContainerTabM>
      ) : windowWidth > 1285 ? (
        <ContainerTabMI>
          <ImgBoxTabM pos={posN} src={picUrl} />
          <TextTab>• {listItem1}</TextTab>
          <TextTab>• {listItem2}</TextTab>
          <TextTab>• {listItem3}</TextTab>
          <TextTab>• {listItem4}</TextTab>
          {isButton ? (
            <ButtonLinkTab href={buttonLink}>{t('Readmore')}</ButtonLinkTab>
          ) : (
            <></>
          )}
        </ContainerTabMI>
      ) : (
        <ContainerMob>
          <ImgBoxMobile pos={posN} src={picUrl} />
          <TextMob>• {listItem1}</TextMob>
          <TextMob>• {listItem2}</TextMob>
          <TextMob>• {listItem3}</TextMob>
          <TextMob>• {listItem4}</TextMob>
          {isButton ? (
            <ButtonLinkMob href={buttonLink}>{t('Readmore')}</ButtonLinkMob>
          ) : (
            <></>
          )}
        </ContainerMob>
      )}
    </>
  );
};
