import React, { useEffect, useState } from 'react';
import {
  useStyles,
  PicBox,
  PicBoxM,
  PicBoxCont,
  PicBoxTextCont,
  PicBoxTextContM,
} from './WhyUsPic.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

export const WhyUsPic = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth > 1250 ? (
        <>
          <PicBox className={styles.firstPic}>
            <PicBoxCont>
              <PicBoxTextCont>{t('whyusone')}</PicBoxTextCont>
            </PicBoxCont>
          </PicBox>
          <PicBox className={styles.secPic}>
            <PicBoxCont>
              <PicBoxTextCont>{t('whyustwo')}</PicBoxTextCont>
            </PicBoxCont>
          </PicBox>
          <PicBox className={styles.thirdPic}>
            <PicBoxCont>
              <PicBoxTextCont>{t('whyusthree')}</PicBoxTextCont>
            </PicBoxCont>
          </PicBox>
        </>
      ) : (
        <>
          <PicBoxM className={styles.firstPic}>
            <PicBoxCont>
              <PicBoxTextContM>{t('whyusone')}</PicBoxTextContM>
            </PicBoxCont>
          </PicBoxM>
          <PicBoxM className={styles.secPic}>
            <PicBoxCont>
              <PicBoxTextContM>{t('whyustwo')}</PicBoxTextContM>
            </PicBoxCont>
          </PicBoxM>
          <PicBoxM className={styles.thirdPic}>
            <PicBoxCont>
              <PicBoxTextContM>{t('whyusthree')}</PicBoxTextContM>
            </PicBoxCont>
          </PicBoxM>
        </>
      )}
    </>
  );
};
