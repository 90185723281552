import React, { useEffect, useState } from 'react';
import { CotnactAboutSec } from '../CotnactAboutSec/CotnactAboutSec';
import {
  Title,
  Text,
  ImgBox,
  Divider,
  Container,
  TopContainer,
  TopContainerTab,
  TopContainerMob,
  DividerCol,
  ContainerCol,
  ContainerReverse,
  ButtonLink,
  TextContainer,
  TextListItem,
} from './HomeAbout.styles';
import { AboutCompTemp } from '../AboutCompTemp/AboutCompTemp';

import { AboutVert } from '../../Atoms/AboutVert/AboutVert';
import { images } from '../../../static/exports';

import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { AboutCompTempVertList } from '../AboutCompTempVertList/AboutCompTempVertList';
import { AboutCompTempVert } from '../AboutCompTempVert/AboutCompTempVert';

export const HomeAbout = () => {
  const { t, i18n } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <AboutCompTemp
        isReverse={false}
        isTitled={true}
        titleTxt={t('profSerc')}
        picUrl={images.Foto7}
        posN={15}
        compTxt={t('BETIsMont')}
        isButton={true}
      />

      <>
        {windowWidth > 1660 ? (
          <>
            <TopContainer>
              <AboutCompTempVertList
                picUrl={images.Foto4}
                listItem1={t('FTLServ')}
                listItem2={t('LTLServ')}
                listItem3={t('SamedayTransport')}
                listItem4={t('Tempcontrltrans')}
                posN={60}
                isButton={true}
                buttonLink={'/LTL'}
              />
              <AboutCompTempVert
                picUrl={images.Foto5}
                compTxt={t('homeAbout2')}
                posN={45}
                isButton={true}
                buttonLink={'/moving'}
              />
              <AboutCompTempVert
                picUrl={images.Foto6}
                compTxt={t('homeAbout3')}
                posN={50}
                isButton={true}
                buttonLink={'/storage'}
              />
            </TopContainer>
          </>
        ) : windowWidth > 1285 ? (
          <>
            <TopContainerTab>
              <AboutCompTempVertList
                picUrl={images.Foto4}
                listItem1={t('FTLServ')}
                listItem2={t('LTLServ')}
                listItem3={t('SamedayTransport')}
                listItem4={t('Tempcontrltrans')}
                posN={60}
                isButton={true}
                buttonLink={'/LTL'}
              />
              <AboutCompTempVert
                picUrl={images.Foto5}
                compTxt={t('homeAbout2')}
                posN={45}
                isButton={true}
                buttonLink={'/moving'}
              />
              <AboutCompTempVert
                picUrl={images.Foto6}
                compTxt={t('homeAbout3')}
                posN={50}
                isButton={true}
                buttonLink={'/storage'}
              />
            </TopContainerTab>
          </>
        ) : (
          <>
            <TopContainerMob>
              <AboutCompTempVertList
                picUrl={images.Foto4}
                listItem1={t('FTLServ')}
                listItem2={t('LTLServ')}
                listItem3={t('SamedayTransport')}
                listItem4={t('Tempcontrltrans')}
                posN={60}
                isButton={true}
                buttonLink={'/LTL'}
              />
              <AboutCompTempVert
                picUrl={images.Foto5}
                compTxt={t('homeAbout2')}
                posN={45}
                isButton={true}
                buttonLink={'/moving'}
              />
              <AboutCompTempVert
                picUrl={images.Foto6}
                compTxt={t('homeAbout3')}
                posN={50}
                isButton={true}
                buttonLink={'/storage'}
              />
            </TopContainerMob>
          </>
        )}
      </>

      <CotnactAboutSec />
    </>
  );
};
