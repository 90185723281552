import styled from 'styled-components';
import { Typography, TypographyProps, Link, LinkProps } from '@mui/material';

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    // marginTop: '100px',
    fontSize: '45px',
    marginRight: '15px',
    marginLeft: '15px',
  },
}));

export const TitleMob = styled(Typography)<TypographyProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    marginTop: '30px',
    fontSize: '38px',
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'center',
  },
}));

export const CustomLink = styled(Typography).attrs<
  TypographyProps & { href: string }
>(({ theme, href }) => ({
  component: 'a',
  href,
}))<TypographyProps & { href: string }>`
  && {
    backgroundColor: '#000',
    color: #000;
    text-decoration: none;
    font-size: 45px;
    // Add other styles as needed
  }
`;

export const Text = styled(Typography)<TypographyProps>(
  ({ theme }) =>
    theme && {
      '&& ': {
        color: '#000',
        fontSize: '20px',
        marginRight: '15px',
        // marginLeft: '15px',
      },
    }
);

export const TextTab = styled(Typography)<TypographyProps>(
  ({ theme }) =>
    theme && {
      '&& ': {
        color: '#000',
        fontSize: '17px',
        marginRight: '15px',
      },
    }
);

export const TextMob = styled(Typography)<TypographyProps>(
  ({ theme }) =>
    theme && {
      '&& ': {
        color: '#000',
        fontSize: '20px',
        marginRight: '15px',
        textAlign: 'center',
      },
    }
);

import { Box, BoxProps } from '@mui/material';

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '70%',
  maxWidth: '450px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const ContainerTab = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '450px',
  marginLeft: '10px',
  marginRight: '10px',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const ContainerTabM = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '400px',
  marginLeft: '10px',
  marginRight: '10px',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const ContainerTabMI = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '350px',
  marginLeft: '10px',
  marginRight: '10px',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const ContainerMob = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '90%',
  maxWidth: '450px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '150px',
  marginBottom: '50px',
  // transform: 'rotateY(180deg)',
}));

export const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  marginLeft: '15px',
  // transform: 'rotateX(180deg)',
}));
export const DividerMob = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  // width: '50%',
  // transform: 'rotateX(180deg)',
}));

export const ImgBox = styled(Box)<BoxProps & { src?: string; pos?: number }>(
  ({ theme, src, pos }) => ({
    height: '450px',
    width: '450px',
    backgroundPosition: `${pos}%`,
    backgroundImage: `url("${src}")`,
    backgroundSize: 'cover',
    marginBottom: '100px',
  })
);

export const ImgBoxTab = styled(Box)<BoxProps & { src?: string; pos?: number }>(
  ({ theme, src, pos }) => ({
    height: '400px',
    width: '400px',
    backgroundPosition: `${pos}%`,
    backgroundImage: `url("${src}")`,
    backgroundSize: 'cover',
    marginBottom: '100px',
  })
);

export const ImgBoxTabM = styled(Box)<
  BoxProps & { src?: string; pos?: number }
>(({ theme, src, pos }) => ({
  height: '350px',
  width: '350px',
  backgroundPosition: `${pos}%`,
  backgroundImage: `url("${src}")`,
  backgroundSize: 'cover',
  marginBottom: '100px',
}));

export const ImgBoxMobile = styled(Box)<
  BoxProps & { src?: string; pos?: number }
>(({ theme, src, pos }) => ({
  height: '350px',
  width: '100%',
  backgroundPosition: `${pos}%`,
  backgroundImage: `url("${src}")`,
  backgroundSize: 'cover',
  marginBottom: '100px',
}));

export const ButtonLink = styled(Link)<LinkProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    fontSize: '20px',
    backgroundColor: `rgba(0, 0, 0, 0.0)`,
    border: '#000 solid 3px',
    maxWidth: '200px',
    height: '40px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    borderRadius: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '800',
    margin: '20px 30px 30px 0',
    '&:hover': {
      color: '#fff',
      backgroundColor: `rgba(0, 0, 0, 1)`,
    },
  },
}));

export const ButtonLinkTab = styled(Link)<LinkProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    fontSize: '20px',
    backgroundColor: `rgba(0, 0, 0, 0.0)`,
    border: '#000 solid 3px',
    maxWidth: '200px',
    height: '60px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    borderRadius: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '800',
    margin: '20px 30px 30px 30px',
    '&:hover': {
      color: '#fff',
      backgroundColor: `rgba(0, 0, 0, 1)`,
    },
  },
}));
export const ButtonLinkMob = styled(Link)<LinkProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    fontSize: '20px',
    backgroundColor: `rgba(0, 0, 0, 0.0)`,
    border: '#000 solid 3px',
    // maxWidth: '200px',
    height: '60px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    borderRadius: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '800',
    margin: '20px 30px 30px 30px',
    '&:hover': {
      color: '#fff',
      backgroundColor: `rgba(0, 0, 0, 1)`,
    },
  },
}));
