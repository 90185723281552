import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HomePage } from './pages/HomePage/HomePage';
import { StoragePage } from './pages/StoragePage/StoragePage';
import { ContactPage } from './pages/ContactPage/ContactPage';
import { MovingPage } from './pages/SDDPage/SDDPage';
import { LTLPage } from './pages/LTLPage/LTLPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Adjusted imports

import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

declare module '@mui/material/styles' {
  interface TypeText {
    black: string;
    grayBorder: string;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#000',
      light: '#ADE4DB',
      dark: '#008080',
    },
    background: {
      default: '#fff',
    },
    text: {
      grayBorder: '#979797',
      primary: '#000',
      secondary: '#fff',
    },
  },
  typography: {
    fontFamily: '"Ubuntu", sans-serif',
  },
});

function App() {
  // Removed unused variables t and changeLanguage
  // const { t, i18n } = useTranslation();
  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };

  // Adjusted scrollBehavior function to specify parameter types
  const scrollBehavior = (to: any, from: any, savedPosition: any) => {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          {/* Adjusted Router */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/storage" element={<StoragePage />} />
            <Route path="/sameDayDelivery" element={<MovingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/LTL" element={<LTLPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
