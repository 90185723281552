import { styled } from '@mui/material/styles';
import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  Link,
  LinkProps,
} from '@mui/material';

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  // backgroundColor: 'pink',
  // border: '5px solid black',
  position: 'relative',
  width: '75.3%',
  margin: '200px auto 0 auto',
  paddingBottom: '60px',
  overflowX: 'auto',
  // marginTop: '200px',
}));

export const ContainerTab = styled(Box)<BoxProps>(({ theme }) => ({
  // backgroundColor: 'pink',
  // border: '5px solid black',
  position: 'relative',
  width: '90%',
  margin: '200px auto 0 auto',
  paddingBottom: '60px',
  overflowX: 'auto',
  // marginTop: '200px',
}));

export const ContainerMob = styled(Box)<BoxProps>(({ theme }) => ({
  // backgroundColor: 'pink',
  // border: '5px solid black',
  position: 'relative',
  width: '100%',
  margin: '200px auto 0 auto',
  paddingBottom: '60px',
  overflowX: 'auto',
  // marginTop: '200px',
}));

export const ModernPicBox = styled(Box)<BoxProps & { src?: string }>(
  ({ theme, src }) => ({
    width: '90%',
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${src}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // transform: 'scaleX(-1)',
    // marginTop: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  })
);

export const ModernPicBoxMob = styled(Box)<BoxProps & { src?: string }>(
  ({ theme, src }) => ({
    width: '90%',
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${src}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // transform: 'scaleX(-1)',
    // marginTop: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  })
);

export const ModernPicBoxIns = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  backgroundColor: `rgba(0, 0, 0, 0.15)`,
  justifyContent: 'left',
  alignItems: 'end',
  // marginBottom: '80px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

export const ModernPicBoxInsMob = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: `rgba(0, 0, 0, 0.55)`,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

export const ModernPicBoxInsTxt = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  // transform: 'scaleX(-1)',
  fontSize: '40px',
  fontWeight: '900',
  margin: '0 30px 40px 30px',
}));

export const ModernPicBoxInsTxtMob = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  // transform: 'scaleX(-1)',
  fontSize: '35px',
  textAlign: 'center',
  fontWeight: '900',
  margin: '0 30px 40px 30px',
}));

export const ButtonLink = styled(Link)<LinkProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    backgroundColor: `rgba(255, 255, 255, 1)`,
    fontSize: '20px',
    border: '#fff solid 3px',
    minWidth: '220px',
    maxWidth: '280px',
    height: '40px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    borderRadius: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '900',
    margin: '20px 30px 30px 0',
    '&:hover': {
      color: '#000',
      backgroundColor: `rgba(255, 255, 255, 1)`,
    },
  },
}));

export const ButtonLinkMob = styled(Link)<LinkProps>(({ theme }) => ({
  '&& ': {
    color: '#000',
    backgroundColor: `rgba(255, 255, 255, 1)`,
    fontSize: '20px',
    border: '#fff solid 3px',
    minWidth: '220px',
    maxWidth: '280px',
    height: '40px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    borderRadius: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '900',
    // margin: '20px 30px 30px 0',
    '&:hover': {
      color: '#000',
      backgroundColor: `rgba(255, 255, 255, 1)`,
    },
  },
}));
