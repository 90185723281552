import React from 'react';

import { HeaderLinksBox } from './HeaderLinks.styles';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import Divider from '@mui/material/Divider';
import { HeaderLink, HeaderLinkMob } from '../../Atoms/HeaderLink/HeaderLink';
import {
  ButtonStyled,
  ButtonStyledMob,
  HeaderLinksBoxMob,
} from './HeaderLinks.styles';
import CssBaseline from '@mui/material/CssBaseline';
export const HeaderLinks: React.FC = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <HeaderLinksBox>
        <HeaderLink title={t('home')} link="/" />
        <HeaderLink title={t('LTL')} link="/LTL" />
        <HeaderLink title={t('SDDS')} link="/sameDayDelivery" />
        <HeaderLink title={t('storage')} link="/storage" />
        <HeaderLink title={t('contact')} link="/contact" />
        {i18n.language === 'en' ? (
          <ButtonStyled onClick={() => changeLanguage('fr')}>Fr</ButtonStyled>
        ) : (
          <ButtonStyled onClick={() => changeLanguage('en')}>Eng</ButtonStyled>
        )}
      </HeaderLinksBox>
    </>
  );
};

export const HeaderLinksMob: React.FC = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <CssBaseline />
      <HeaderLinksBoxMob>
        <HeaderLinkMob title={t('home')} link="/" />
        <HeaderLinkMob title={t('LTL')} link="/LTL" />
        <HeaderLinkMob title={t('SDDS')} link="/sameDayDelivery" />
        <HeaderLinkMob title={t('storage')} link="/storage" />
        <HeaderLinkMob title={t('contact')} link="/contact" />
        <Divider sx={{ mb: 2, backgroundColor: 'red' }} />
        {i18n.language === 'en' ? (
          <ButtonStyledMob onClick={() => changeLanguage('fr')}>
            Fr
          </ButtonStyledMob>
        ) : (
          <ButtonStyledMob onClick={() => changeLanguage('en')}>
            Eng
          </ButtonStyledMob>
        )}
      </HeaderLinksBoxMob>
    </>
  );
};
