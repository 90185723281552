import React, { useEffect, useRef, useState } from 'react';
import trucksVid from '../../../static/trucksVid2.mp4';
import { HomeVidProps } from './HomeVid.behavior';
import {
  HomeVidStyledMax,
  HomeVidStyledMid,
  HomeVidStyledMin,
  BoxStyled,
  BoxStyledS,
} from './HomeVid.styles';
import musciMp3 from '../../../static/music.mp3';

export const HomeVid: React.FC<HomeVidProps> = ({ children }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeMusic = () => {
    setIsMusicPlaying(!isMusicPlaying);
    if (audioRef.current) {
      if (isMusicPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  return (
    <>
      {windowWidth > 1700 ? (
        <>
          <audio ref={audioRef} src={musciMp3} />
          <BoxStyled imstp={true} ismp={isMusicPlaying}>
            {children}
            <HomeVidStyledMax
              onClick={changeMusic}
              className="videoTag"
              autoPlay
              loop
              muted
            >
              <source src={trucksVid} type="video/mp4" />
            </HomeVidStyledMax>
          </BoxStyled>
        </>
      ) : windowWidth > 1400 ? (
        <>
          <audio ref={audioRef} src={musciMp3} />
          <BoxStyled imstp={true} ismp={isMusicPlaying}>
            {children}
            <HomeVidStyledMid
              onClick={changeMusic}
              className="videoTag"
              autoPlay
              loop
              muted
            >
              <source src={trucksVid} type="video/mp4" />
            </HomeVidStyledMid>
          </BoxStyled>
        </>
      ) : windowWidth > 1024 ? (
        <>
          <audio ref={audioRef} src={musciMp3} />
          <BoxStyled imstp={false} ismp={isMusicPlaying}>
            {children}
            <HomeVidStyledMin className="videoTag" autoPlay loop muted>
              <source src={trucksVid} type="video/mp4" />
            </HomeVidStyledMin>
          </BoxStyled>
        </>
      ) : (
        <BoxStyled imstp={false} ismp={isMusicPlaying}>
          {children}
          <HomeVidStyledMin className="videoTag" autoPlay loop muted>
            <source src={trucksVid} type="video/mp4" />
          </HomeVidStyledMin>
        </BoxStyled>
      )}
    </>
  );
};
