import { styled } from '@mui/material/styles';
import { AppBar, AppBarProps } from '@mui/material';

export const HeaderBox = styled(AppBar)<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '80px',
  paddingLeft: '17%',
  paddingRight: '17%',
  boxShadow: 'none',
}));

export const HeaderBoxMid = styled(AppBar)<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '80px',
  paddingLeft: '10%',
  paddingRight: '10%',
  boxShadow: 'none',
}));

export const HeaderBoxMob = styled(AppBar)<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '80px',
  paddingLeft: '10%',
  paddingRight: '10%',
  boxShadow: 'none',
}));

export const HeaderBoxMin = styled(AppBar)<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '80px',
  paddingLeft: '5%',
  paddingRight: '5%',
  boxShadow: 'none',
}));

export const HeaderBoxClear = styled(AppBar)<AppBarProps>(({ theme }) => ({
  filter: 'invert(100%)',
  backgroundColor: `rgba(0, 0, 0, 0.0)`,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '110px',
  paddingLeft: '17%',
  paddingRight: '17%',
  boxShadow: 'none',
}));

export const HeaderBoxMidClear = styled(AppBar)<AppBarProps>(({ theme }) => ({
  filter: 'invert(100%)',
  backgroundColor: `rgba(0, 0, 0, 0.0)`,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '80px',
  paddingLeft: '10%',
  paddingRight: '10%',
  boxShadow: 'none',
}));

export const HeaderBoxMobClear = styled(AppBar)<AppBarProps>(({ theme }) => ({
  filter: 'invert(100%)',
  backgroundColor: `rgba(0, 0, 0, 0.0)`,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '80px',
  paddingLeft: '10%',
  paddingRight: '10%',
  boxShadow: 'none',
}));

export const HeaderBoxMinClear = styled(AppBar)<AppBarProps>(({ theme }) => ({
  filter: 'invert(100%)',
  backgroundColor: `rgba(0, 0, 0, 0.0)`,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: '80px',
  paddingLeft: '5%',
  paddingRight: '5%',
  boxShadow: 'none',
}));

import { alpha } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';

export const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const MobMenBox = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  padding: ' 20px 50px 50px 50px',
  backgroundColor: '#fff',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

import IconButton from '@mui/material/IconButton';

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',
}));

export const MobMenBoxIcon = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'right',
}));
